// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
// import "channels"

Rails.start()
ActiveStorage.start()

const images    = require.context('../images', true)
const imagePath = (name) => images(name, true)

import "@hotwired/turbo-rails"

// css
import '../stylesheets/application.scss'

// vendor
import '@fortawesome/fontawesome-free/js/fontawesome'
import 'script-loader!../src/vendor/slick'
import 'script-loader!../src/vendor/lightzoom'
import 'script-loader!sweetalert2/dist/sweetalert2.all'
import 'script-loader!jquery-validation'
import 'script-loader!jquery-validation/dist/localization/messages_el'
import 'script-loader!ez-plus/src/jquery.ez-plus'
import 'script-loader!../src/mobile-menu'
import 'script-loader!../src/search'
import 'script-loader!../src/filters'

//pages
import 'script-loader!../src/home'
import 'script-loader!../src/product'
import 'script-loader!../src/checkout'
import 'script-loader!../src/category'

$(document).on('turbo:load', function(){
    var timer               = null
    var doneTypingInterval  = 500

    init_validation();

    $("#home-search").on("keyup",function(){
        clearTimeout(timer);

        var url     = $(this).attr('url');
        var words   = $(this).val();

        timer = setTimeout(function(){
            $.ajax({
                method: 'POST',
                url: url,
                dataType: "html",
                data: { words: words },
                success: function(response){
                    $('.search-items').empty().append(response)
                }
            })
        }, doneTypingInterval);
    });

    function init_validation(){
        $('.simple_form:not(.dont-validate)').validate({
            ignore: [],
            invalidHandler: function(event, validator) {
                $.each(validator.errorList, function(index, error){
                    var element              = error.element
                    var content_tabs_wrapper = $(element).closest('.content-tabs-wrapper')
                    var all_contents         = content_tabs_wrapper.find('.tab-content')
                    var element_content      = $(element).closest('.tab-content')

                    if(content_tabs_wrapper){

                        $('.tab-item > a').removeClass('active')
                        $('.tab-item[type='+element_content.attr('type')+'] > a').addClass('active')
                        if(element_content.is(":visible"))
                            all_contents.not(element_content).hide()
                        else{
                            all_contents.hide()
                            element_content.show();
                        }

                        return false;
                    }
                });
            }
        });
    }

    $('.manufacturer-slide').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
    });

    $('.image-zoom').lightzoom();

    $('#is-dimensions').find('.item').first().click()

    $('.mobile-main-menu .item i').on('click tap', function () {
        var item        = $(this).closest(".item")
        var arrow       = $(this)
        var sub_menu    = item.find('.mobile-sub-menu')

        if (sub_menu.is(":hidden")) {
            arrow.css("transform", "rotate(90deg)")
            sub_menu.slideDown('fast')
        } else if (sub_menu.is(":visible")) {
            arrow.css("transform", "rotate(0deg)")
            sub_menu.slideUp('fast')
        }
    });
})